<template>
  <b-overlay
    :show="OverLay"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card
      no-body
    >
      <b-card-body>
        <div class="d-flex justify-content-between  flex-wrap">
          <div>
            <b-row>
              <b-col>
                <b-form-select
                  v-model="TimeToRefresh"
                  class="d-inline-block mr-1"
                  :options="RefreshOP"
                  @change="getTable()"
                />
              </b-col>
              <b-col>
                <b-badge variant="primary">
                  Refresh in {{ DiffRefresh }} seconds
                </b-badge>
              </b-col>
            </b-row>
          </div>

          <div>
            <b-row>
              <b-col>
                <div class="d-flex justify-content-end">

                  <b-form-select
                    v-model="table"
                    class="d-inline-block mr-1"
                    :options="TableList"
                  />

                  <b-form-input
                    v-model="roundId"
                    class="d-inline-block mr-1"
                    placeholder="ID"
                  />

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="btn-icon"
                    block
                    @click="getTable()"
                  >
                    <feather-icon icon="SearchIcon" />
                    Search
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-card-body>
      <b-table
        striped
        hover
        responsive
        class="position-relative items-center"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>
        <template #cell(username)="data">
          {{ NameTable(data.item.username) }}
        </template>
        <template #cell(Status)="data">
          <div class="text-nowrap">
            <b-badge
              :variant="`${data.item.Status === 'won' ? 'light-success' :data.item.Status === 'lose' ? 'light-danger' :data.item.Status === 'draw' ? 'light-warning' :data.item.Status === 'running' ? 'light-info' :'light-secondary' }`"
            >
              {{ data.item.Status }}
            </b-badge>
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`invoice-row-${data.item.id}-preview-icon`"
              v-b-modal.modal-1
              icon="EditIcon"
              size="16"
              class="mx-1"
              @click="GetDetail(data.item)"
            />
          </div>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
      <b-modal
        id="modal-1"
        size="xl"
        title="Detail"
        ok-title="Print"
        @ok="Print"
      >
        <div v-if="Detail">
          <div>
            <b-row class="match-height">
              <b-col
                md="2"
                cols="2"
                class="mb-1"
              >
                <div>
                  <div class="card-cradit-white white">
                    <div class="p-1">
                      <span>Type</span>
                      <h3>{{ Detail.sportsType }}</h3>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col
                md="2"
                cols="2"
                class="mb-1"
              >
                <div>
                  <div class="card-cradit-white white">
                    <div class="p-1">
                      <span>Ref</span>
                      <h3>{{ Detail.refNo }}</h3>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col
                md="2"
                cols="2"
                class="mb-1"
              >
                <div>
                  <div class="card-cradit-white white">
                    <div class="p-1">
                      <span>Status</span>
                      <h3>{{ Detail.isHalfWonLose ? 'Half ' + Detail.status : Detail.status }}</h3>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col
                md="2"
                cols="2"
                class="mb-1"
              >
                <div>
                  <div class="card-cradit-white white">
                    <div class="p-1">
                      <span>Stake</span>
                      <h3>{{ Detail.stake }}</h3>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col
                md="2"
                cols="2"
                class="mb-1"
              >
                <div>
                  <div class="card-cradit-white white">
                    <div class="p-1">
                      <span>Odds</span>
                      <h3>{{ Detail.odds }}</h3>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col
                md="2"
                cols="2"
                class="mb-1"
              >
                <div>
                  <div class="card-cradit-white white">
                    <div class="p-1">
                      <span>Winlose</span>
                      <h3>{{ Detail.winLost }}</h3>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>

          <template>
            <b-table
              striped
              hover
              responsive
              class="position-relative items-center"
              :items="Detail.subBet"
              :fields="fieldsDetail"
            >
              <template #cell(index)="data">
                {{ perPage * (currentPage - 1) + (data.index + 1) }}
              </template>
              <template #cell(status)="data">
                <div class="text-nowrap">
                  <b-badge
                    :variant="`${data.item.status === 'won' ? 'light-success' :data.item.status === 'lose' ? 'light-danger' :data.item.status === 'draw' ? 'light-warning' :'light-info' }`"
                  >
                    {{ data.item.isHalfWonLose ? 'Half ' + data.item.status : data.item.status }}
                  </b-badge>
                </div>
              </template>

            </b-table>
          </template>
        </div>
      </b-modal>
    </b-card>
  </b-overlay>
</template>

<script>
// import vSelect from 'vue-select'
import moment from 'moment-timezone'
import {
  BModal, BCard, BTable,
  // , BAvatar
  BFormGroup, BFormSelect, BPagination,
  // , BInputGroup
  BFormInput,
  // , BInputGroupAppend
  BButton, BCardBody, VBToggle,
  BOverlay, BIconController,
  BBadge,
  BRow,
  BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    // vSelect,

    BModal,
    BCard,
    BTable,
    // BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    // BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    BOverlay,
    BIconController,
    BBadge,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      DiffRefresh: 0,
      TimeRefresh: moment().add(this.TimeToRefresh + 1, 'seconds'),
      TimeToRefresh: 60,
      RefreshOP: [
        { text: '10 second', value: 10 },
        { text: '30 second', value: 30 },
        { text: '1 minute', value: 60 },
        { text: '5 minute', value: 300 },
        { text: '10 minute', value: 600 },
      ],
      OverLay: false,
      depositdata: [],
      bankData: '',
      bankname: '',
      bankcode: '',
      optionsMember: '',
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'index', label: 'no.' },
        { key: 'Status', label: 'Status' },
        { key: 'username', label: 'Table' },
        { key: 'roundId', label: 'ID' },
        { key: 'betAmount', label: 'Amount' },
        { key: 'winAmount', label: 'WinAmount' },
        {
          key: 'created_at',
          label: 'TimeStamp',
          formatter: value => moment(value)
            .tz('Asia/Seoul')
            .format('DD/MM/YYYY HH:mm:ss'),
        },
        { key: 'actions', label: 'Action' },
      ],
      fieldsDetail: [
        { key: 'index', label: 'no.' },
        { key: 'status', label: 'status' },
        { key: 'sportType', label: 'type' },
        { key: 'marketType', label: 'marketType' },
        { key: 'league', label: 'league' },
        { key: 'betOption', label: 'betOption' },
        { key: 'match', label: 'match' },
        { key: 'hdp', label: 'hdp' },
        { key: 'odds', label: 'odds' },
        { key: 'liveScore', label: 'liveScore' },
        { key: 'htScore', label: 'htScore' },
        { key: 'ftScore', label: 'ftScore' },
        {
          key: 'kickOffTime',
          label: 'kickOffTime',
          formatter: value => moment(value)
            .tz('Asia/Seoul')
            .format('DD/MM/YYYY HH:mm:ss'),
        },
        {
          key: 'winlostDate',
          label: 'winlostDate',
          formatter: value => moment(value)
            .tz('Asia/Seoul')
            .format('DD/MM/YYYY HH:mm:ss'),
        },
      ],
      table: 'power9876540001',
      roundId: '',
      TableList: [
        { text: 'Table 1', value: 'power9876540001' },
        { text: 'Table 2', value: 'power9876540002' },
      ],
      /* eslint-disable global-require */
      items: [],
      addNewDataSidebar: false,
      Detail: [],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    this.StopRefresh()
    this.getTable()
    this.totalRows = this.items.length
  },
  methods: {
    StartRefresh() {
      this.Interval = setInterval(() => {
        const DateNow = moment()
        this.DiffRefresh = this.TimeRefresh.diff(DateNow, 'seconds')
        if (this.DiffRefresh <= 0) {
          this.getTable()
        }
      }, 1000)
    },
    StopRefresh() {
      clearInterval(this.Interval)
    },
    getTable() {
      this.OverLay = true
      this.TimeRefresh = moment().add(this.TimeToRefresh + 1, 'seconds')
      const params = {
        table: this.table,
        roundId: this.roundId,
      }
      // const params = {
      //   table: 'power0970641960',
      //   roundId: this.roundId,
      // }
      this.$http
        .get('/sbobet/table/list', { params })
        // .get('http://127.0.0.1:3333/api/sbobet/table/list', { params })
        .then(response => {
          this.onFiltered(response.data)
          this.OverLay = false
          this.StopRefresh()
          this.StartRefresh()
        })
        .catch(error => console.log(error), this.OverLay = false)
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems
      this.totalRows = filteredItems.length
      this.currentPage = 1
      filteredItems.forEach((item, index) => {
        this.items[index].Status = 'pending'
        this.items[index].winAmount = 0
      })
      filteredItems.forEach((item, index) => {
        this.GetSettle(item.username, item.roundId, index)
      })
    },
    async GetSettle(table, roundId, index) {
      try {
        const params = {
          table,
          roundId,
        }
        const { data: res } = await this.$http.get('/sbobet/table/findsettle', { params })
        // const { data: res } = await this.$http.get('http://127.0.0.1:3333/api/sbobet/table/findsettle', { params })
        if (res) {
          if (res.ResultType === 0) {
            this.items[index].Status = 'won'
          } else if (res.ResultType === 1) {
            this.items[index].Status = 'lose'
          } else if (res.ResultType === 2) {
            this.items[index].Status = 'draw'
          }
          this.items[index].winAmount = res.winAmount
          console.log(res)
        } else {
          this.items[index].Status = 'running'
        }
      } catch (error) {
        console.log(error)
      }
    },
    NameTable(Val) {
      const Index = this.TableList.findIndex(({ value }) => value === Val)
      if (Index >= 0) {
        return this.TableList[Index].text
      }
      return '-'
    },
    GetDetail(Ele) {
      this.Detail = []
      const params = {
        roundId: Ele.roundId,
      }
      this.$http
        .get('/sbobet/table/detail', { params })
        .then(response => {
          if (response.data.error.id === 0) {
            // eslint-disable-next-line prefer-destructuring
            this.Detail = response.data.result[0]
          } else {
            this.SwalError(response.data.error.msg)
          }
        })
        .catch(error => console.log(error))
    },
    Print() {
      // console.log(this.Detail)
      this.OverLay = true
      this.Detail.table = this.NameTable(this.Detail.username)
      this.$http
        .post(
          '/print/pdf',
          this.Detail,
          {
            responseType: 'arraybuffer',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/pdf',
            },
          },
        )
      // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.OverLay = false
          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
          const iframe = document.createElement('iframe') // load content in an iframe to print later
          document.body.appendChild(iframe)
          iframe.style.display = 'none'
          iframe.src = url
          // eslint-disable-next-line func-names
          iframe.onload = function () {
            setTimeout(() => {
              iframe.focus()
              iframe.contentWindow.print()
            }, 1)
          }
        })
        .catch(error => console.log(error), this.OverLay = false)
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
background-color:$product-details-bg;
}
